<template>
  <div>
    <modal-edit-text
      v-if="showEditText"
      :showModal="showEditText"
      :onClickCancel="hideModal"
      :locationId="locationId"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="setShowEditText"
      :editLabel="'Edit'"
    >
      <template v-slot:title>Publication texts</template>
    </ui-section-header>

    <table class="table is-fullwidth is-narrow">
      <thead>
        <tr>
          <th
            v-for="(textLanguage, index) in option.Texts"
            :key="index"
            width="50%"
          >
            {{ $t('LanguageLabels.Language' + textLanguage.LanguageId) }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td
            v-for="(textLanguage, index) in option.Texts"
            :key="index"
            width="50%"
          >
            {{ textLanguage.Name }}
          </td>
        </tr>
        <tr>
          <td
            v-for="(textLanguage, index) in option.Texts"
            :key="index"
            width="50%"
          >
            <div class="content" v-html="textLanguage.Description"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditText = () => import('@/components/Options/ModalEditOptionText')

export default {
  components: {
    'modal-edit-text': ModalEditText,
  },

  props: {},

  data() {
    return {
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      showEditText: false,
    }
  },

  computed: {
    ...mapState('optionStore', ['option']),
  },

  created() {},

  methods: {
    setShowEditText() {
      this.showEditText = true
    },

    hideModal() {
      this.showEditText = false
    },
  },
}
</script>
